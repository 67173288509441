body {
    padding: 0px;
    margin: 0px;
    background: transparent;
    .navbar {
        li {
            list-style: none;
            display: inline-block;
            .active {
               color: rgba(0,0,0,.9);
            }
        }
    }
    .contents {
        padding: 10px;
        width: 100%;
        max-width: 1197px;
        margin: 0px auto;
        .thumbs {
            margin-bottom: 20px;
            img {
                width: 100%;
            }
        }
        .single-image-grid {
            img {
                width: 100%;
            }
        }
    }
    video {
        width: 100%;
    }
    .navbar-brand {
        max-height: 150px;
        img {
            width: auto;
            max-height: 80px;
        }
    }
}
